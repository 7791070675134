<template>
  <div class="mt-1 mx-3">
    <insurer-risks>
    </insurer-risks>
  </div>
</template>

<script>
import InsurerRisks from '@/components/profile/companies-products/insurance-risks/index.vue'
export default {
  props: {
    msg: String
  },
  components: {
    InsurerRisks,
  },
  created() {
  }
}
</script>