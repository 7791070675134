var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-1"},[_c('b-overlay',{attrs:{"center":"","show":_vm.isLoading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center mt-5"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Operazione in corso...")])],1)]},proxy:true}])},[(!_vm.isLoading)?_c('div',[(_vm.ok)?_c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, handleSubmit }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',{attrs:{"header":"Dati Identificativi","header-tag":"header"}},[_c('b-card-text',[_c('b-row',[_c('div',{staticClass:"form-group col-md-3"},[_c('base-select',{attrs:{"name":_vm.beForm[_vm.rep].insurer_id.label,"vid":"insurer_id","label":_vm.beForm[_vm.rep].insurer_id.label,"options":_vm.companies,"rules":_vm.getRules('insurer_id'),"disabled":_vm.companies.find(
                          (company) => company.value == _vm.form[_vm.rep].insurer_id
                        ).importer_id
                          ? true
                          : false},on:{"input":_vm.onInputInsurer},model:{value:(_vm.form[_vm.rep].insurer_id),callback:function ($$v) {_vm.$set(_vm.form[_vm.rep], "insurer_id", $$v)},expression:"form[rep].insurer_id"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-3"},[_c('base-select',{attrs:{"name":_vm.beForm[_vm.rep].risk_branch_id.label,"vid":"risk_branch_id","label":_vm.beForm[_vm.rep].risk_branch_id.label,"options":_vm.risk_branches,"rules":_vm.getRules('risk_branch_id'),"disabled":_vm.companies.find(
                          (company) => company.value == _vm.form[_vm.rep].insurer_id
                        ).importer_id
                          ? true
                          : false},model:{value:(_vm.form[_vm.rep].risk_branch_id),callback:function ($$v) {_vm.$set(_vm.form[_vm.rep], "risk_branch_id", $$v)},expression:"form[rep].risk_branch_id"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-3"},[_c('base-input',{attrs:{"name":_vm.beForm[_vm.rep].code.label,"vid":"code","label":"Codice rischio","placeholder":"Inserisci un codice","rules":_vm.getRules('code'),"readonly":_vm.companies.find(
                          (company) => company.value == _vm.form[_vm.rep].insurer_id
                        ).importer_id
                          ? true
                          : false},model:{value:(_vm.form[_vm.rep].code),callback:function ($$v) {_vm.$set(_vm.form[_vm.rep], "code", $$v)},expression:"form[rep].code"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-3"},[_c('base-input',{attrs:{"name":_vm.beForm[_vm.rep].title.label,"vid":"title","label":_vm.beForm[_vm.rep].title.label,"placeholder":"Inserisci una descrizione","rules":_vm.getRules('title'),"readonly":_vm.companies.find(
                          (company) => company.value == _vm.form[_vm.rep].insurer_id
                        ).importer_id
                          ? true
                          : false},model:{value:(_vm.form[_vm.rep].title),callback:function ($$v) {_vm.$set(_vm.form[_vm.rep], "title", $$v)},expression:"form[rep].title"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-3"},[_c('base-select',{attrs:{"vid":"delay_days","name":_vm.beForm[_vm.rep].delay_days.label,"label":_vm.beForm[_vm.rep].delay_days.label,"options":_vm.beForm[_vm.rep].delay_days.options,"rules":_vm.getRules('delay_days')},model:{value:(_vm.form[_vm.rep].delay_days),callback:function ($$v) {_vm.$set(_vm.form[_vm.rep], "delay_days", $$v)},expression:"form[rep].delay_days"}})],1)])],1)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.customInputs[_vm.rep].length),expression:"customInputs[rep].length"}],staticClass:"mt-2"},[_c('b-card',{attrs:{"header":"Attributi personalizzati","header-tag":"header"}},[_c('b-card-text',[_c('custom-inputs',{attrs:{"customInputs":_vm.customInputs[_vm.rep],"beForm":_vm.beForm[_vm.rep],"beRules":_vm.beRules[_vm.rep]},model:{value:(_vm.form[_vm.rep]),callback:function ($$v) {_vm.$set(_vm.form, _vm.rep, $$v)},expression:"form[rep]"}})],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"py-2 mb-4"},[_c('b-button',{attrs:{"type":"button","disabled":invalid,"variant":"lisaweb","size":"sm"},on:{"click":function($event){return handleSubmit(_vm.onSubmit)}}},[_vm._v("\n                Salva\n              ")]),_vm._v(" "),_c('b-button',{staticClass:"btn-reset float-right",attrs:{"variant":"outline-lisaweb","size":"sm"},on:{"click":function($event){return _vm.$router.back()}}},[_c('b-icon-chevron-double-left',{attrs:{"font-scale":"0.9"}}),_vm._v("Torna indietro")],1)],1)],1)]}}],null,false,2998799725)})],1):_c('div',[_vm._v("\n        "+_vm._s(_vm.errorMessage)+"\n      ")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }