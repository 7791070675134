<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].insurer_id.label"
                        vid="insurer_id"
                        :label="beForm[rep].insurer_id.label"
                        v-model="form[rep].insurer_id"
                        :options="companies"
                        @input="onInputInsurer"
                        :rules="getRules('insurer_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].risk_branch_id.label"
                        vid="risk_branch_id"
                        :label="beForm[rep].risk_branch_id.label"
                        v-model="form[rep].risk_branch_id"
                        :options="risk_branches"
                        :rules="getRules('risk_branch_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].code.label"
                        vid="code"
                        label="Codice Rischio"
                        v-model="form[rep].code"
                        placeholder="Inserisci un codice"
                        :rules="getRules('code')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        :label="beForm[rep].title.label"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="delay_days"
                        :name="beForm[rep].delay_days.label"
                        :label="beForm[rep].delay_days.label"
                        v-model="form[rep].delay_days"
                        :options="beForm[rep].delay_days.options"
                        :rules="getRules('delay_days')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import CustomInputs from "@/components/form/CustomInputs";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "insurance_risk",
      id: this.$route.params.id,
      companies: [],
      risk_branches: [],
      risks: [],
      form: {
        risk_type_id: null,
        insurance_risk: {
          code: null,
          insurer_id: null,
          risk_branch_id: null,
          title: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    CustomInputs,
    BaseInput,
    BaseSelect,
  },
  mounted() {
    this.companies = this.getInsurers();
    this.risk_branches = this.getBranches()();
    this.risks = this.getRisks();
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.store(this.repository)
        .then(() => {
          this.$store.dispatch("auth/products");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Prodotto Creato`,
          });
          this.shortcut(
            "module.PROF",
            null,
            "#InsurerRisks",
            "filterInsuranceRisks"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onInputInsurer(insurer) {
      this.form[this.rep].insurer_id = insurer;
      this.form[this.rep].risk_branch_id = null;
      this.form.risk_type_id = null;
    },
    ...mapGetters("auth", {
      getRisks: "risks",
      getBranches: "branches",
      getInsurers: "insurers",
    }),
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
